import type { AuthContext } from "#orie";

export enum AuthResponseStatus {
  PASSWORD_UPDATED = "PASSWORD_UPDATED",
  EMAIL_SENT = "EMAIL_SENT",
  VERIFICATION_ERROR = "VERIFICATION_ERROR",
  TOKEN_REVOKED = "TOKEN_REVOKED",
}

export enum AccountRegisterStatus {
  MUST_VERIFY_EMAIL = "MUST_VERIFY_EMAIL",
  SUCCESS = "SUCCESS",
}

export interface LoginInput {
  username: string;
  password: string;
}

export interface RefreshTokenInput {
  refresh_token: string;
}

export interface ForgotPasswordInput {
  email: string;
}

export interface NewPasswordWithCodeInput {
  email: string;
  token: string;
  password: string;
  password_confirmation: string;
}

export interface UpdatePasswordInput {
  old_password: string;
  password: string;
  password_confirmation: string;
}

export interface UserRegisterInput {
  name: string;
  email: string;
  phone?: string;
  password: string;
  password_confirmation: string;
  dob?: string;
  country?: string;
  gender?: string;
  intent?: string;
}

export interface SocialLoginInput {
  provider: string;
  token: string;
}

export interface UserProfile {
  id: string | number;
  sub: string;
  name: string;
  first_name: string;
  last_name?: string;
  email: string;
  email_verified?: boolean;
  gender: string;
  date_of_birth?: string;
  last_active?: string;
}

export interface AuthPayload {
  access_token?: string;
  refresh_token?: string;
  expires_in?: number;
  token_type: string;
  user?: UserProfile;
  context?: AuthContext;
}

export interface RefreshTokenPayload {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export interface AuthResponse {
  status: AuthResponseStatus;
  message?: String;
}

export interface RegisterResponse {
  tokens?: AuthPayload;
  status: AccountRegisterStatus;
}

export enum StoreStatus {
  PENDING = "PENDING",
  PENDING_APPROVAL = "PENDING_APPROVAL",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DISABLED = "DISABLED",
  SUSPENDED = "SUSPENDED",
}

export enum WorkspaceAction {
  ONBOARDING = "ONBOARDING",
  SELECT_PLAN = "SELECT_PLAN",
  ACCEPT_SERVICES_AGREEMENT = "ACCEPT_SERVICES_AGREEMENT",
  REVIEW_REJECTED_ACCOUNT = "REVIEW_REJECTED_ACCOUNT",
}

export enum StoreOnboardingStatus {
  NOT_STARTED = "NOT_STARTED",
  PARTIAL = "PARTIAL",
  COMPLETE = "COMPLETE",
}

export interface OnboardStep {
  handle: string;
  title: string;
  name?: string;
  description?: string;
  complete: boolean;
  mandatory: boolean;
}

export interface WorkspaceStatus {
  plan?: string;
  status: StoreStatus;
  store_onboarding_status?: StoreOnboardingStatus;
  trial: boolean;
  has_contract: boolean;
  trial_days_left: number;
  workspace_actions: WorkspaceAction[];
  next_steps: OnboardStep[];
}

type FeatureUsageMeterMeasure = {
  type: "feature";
  limit?: boolean; // if `false`, then the feature is not available, limitless if `null`
  usage: number;
};

type VolumeUsageMeterMeasure = {
  type: "volume";
  limit?: number; // if `0`, then the feature is not available, limitless if `null`
  usage: number;
};

export type FeatureServiceUsage = Record<string, FeatureUsageMeterMeasure>;
export type VolumeServiceUsage = Record<string, VolumeUsageMeterMeasure>;
export type ServiceUsage = Record<
  string,
  FeatureUsageMeterMeasure | VolumeUsageMeterMeasure
>;

export interface UserWorkspace {
  name: string;
  code: string;
  avatar?: string;
  currency: string;
  status?: WorkspaceStatus;
  usage: ServiceUsage;
  accepted_terms: boolean;
}

export interface UserWorkspaceAccess {
  name: string;
  code: string;
  avatar?: string;
  owner: UserWorkspaceOwner;
  active: boolean;
}

export interface UserWorkspaceOwner {
  id: string;
  name: string;
  email: string;
}

declare module "#orie" {
  interface AuthContext {
    workspace?: UserWorkspace;
    workspaces: UserWorkspaceAccess[];
    roles: string[];
    permissions: string[];
    tokens: {
      search: string;
      analytics: string;
    };
  }
}
